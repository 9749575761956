import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from './Navbar'
import Footer from './Footer'

const MainLayout = ({ children }) => (
    <>
        <Helmet>
            <link href="https://api.fontshare.com/v2/css?f[]=general-sans@701,200,500,301,201,300,601,600,401,501,400,700&display=swap" rel="stylesheet" />
            <link rel="icon" type="image/x-icon" />
            <link rel="apple-touch-icon" />
        </Helmet>
        <Navbar />{children}<Footer />
    </>
)


export default MainLayout