import React, { useState } from 'react'
import CallIcon from '@mui/icons-material/Call'
import { container } from "./style.js"
import { Link } from 'gatsby'
import '../styles/navbarMenu.css'
import { StaticImage } from 'gatsby-plugin-image'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Navbar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen)
    }

    return (
        <>
            <nav className="bg-secondary-dark items-center fixed w-full z-50 top-0 left-0 border-b border-gray-200 shadow-md rounded-b-lg">
                <div className={`${container} mx-auto`}>
                    <div className="max-w-screen flex flex-wrap items-center justify-between mx-auto py-4">
                        <Link to='/'>
                            <div className='flex'>
                                <StaticImage src="../assets/images/toothLogo.png" className='h-[50px] w-[50px]' alt='Parma Family Dental Center Logo' />
                                <p className="self-center text-[17px] ml-4 font-semibold whitespace-nowrap">Parma Family <br />Dental Center</p>
                            </div>
                        </Link>
                        <div className="flex md:order-2">

                            <a href='tel:440-885-1111' className='pointer' aria-label='phone'>
                                <div className='hidden navs:flex'>
                                    <div className='p-2 mr-3 border-2 border-[#1376F8] rounded-lg'>
                                        <CallIcon sx={{ color: '#1376F8' }} />
                                    </div>
                                    <div>
                                        <p className="text-black text-[14px] font-semibold">Please call for appointment</p>
                                        <p className='text-[14px]'>440-885-1111</p>
                                    </div>
                                </div>
                            </a>

                            <button data-collapse-toggle="navbar-sticky" type="button" onClick={toggleMobileMenu} className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg navs:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-sticky" aria-expanded={isMobileMenuOpen ? 'true' : 'false'}>
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                                </svg>
                            </button>
                        </div>
                        <div className={`items-center hidden w-full navs:flex navs:w-auto navs:order-1`} id="navbar-sticky">
                            <ul className="flex flex-col p-4 navs:p-0 mt-4 font-medium border rounded-lg navs:flex-row navs:space-x-8 navs:mt-0 navs:border-0">
                                <li>
                                    <Link to="/" activeClassName="font-semibold" className={`block py-2 pl-3 pr-4 text-black rounded md:bg-transparent md:p-0`} aria-current="page">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us" activeClassName="font-semibold" className={`block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0`}>About us</Link>
                                </li>
                                <li className="dropdown">
                                    <Link to="/services" activeClassName="font-semibold" className={`block dropbtn py-2 pl-3 pr-4 text-gray-900 rounded hover-bg-gray-100 md:hover-bg-transparent md:hover-text-blue-700 md:p-0`}>Services <KeyboardArrowDownIcon /></Link>
                                    <div className="dropdown-content rounded-md">
                                        <div className='grid grid-cols-2'>
                                            <div className='col-span-1'>
                                                <Link to="/services/cosmetic-dentistry" className='hover:bg-primary-light hover:text-white'>Cosmetic Dentistry</Link>
                                                <Link to="/services/preventive-dentistry" className='hover:bg-primary-light hover:text-white'>Preventive Dentistry</Link>
                                                <Link to="/services/restorative-dentistry" className='hover:bg-primary-light hover:text-white'>Restorative Dentistry</Link>
                                                <Link to="/services/emergency-dental" className='hover:bg-primary-light hover:text-white'>Emergency Dental Care</Link>
                                            </div>
                                            <div className='col-span-1'>
                                                <Link to="/services/cleanings-hygiene" className='hover:bg-primary-light hover:text-white'>Cleanings & Hygeine</Link>
                                                <Link to="/services/root-canal" className='hover:bg-primary-light hover:text-white'>Root Canal Therapy</Link>
                                                <Link to="/services/dentures" className='hover:bg-primary-light hover:text-white'>Complete & Partial Dentures</Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <Link to="/faqs" activeClassName="font-semibold" className={`block py-2 pl-3 pr-4 text-gray-900 rounded hover-bg-gray-100 md:hover-bg-transparent md:hover-text-blue-700 md:p-0`}>FAQs</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {isMobileMenuOpen && (
                    <div className="md:hidden">
                        <div className={`items-center px-4 w-full md:flex md:w-auto md:order-1 `} id="navbar-sticky">
                            <ul className="flex flex-col md:p-0 font-medium rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 py-4">
                                <li>
                                    <Link to="/" onClick={toggleMobileMenu} activeClassName="font-semibold" className="block py-2 pl-3 pr-4 text-black rounded md:bg-transparent md:p-0" aria-current="page">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us" onClick={toggleMobileMenu} activeClassName="font-semibold" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 ">About us</Link>
                                </li>
                                <li>
                                    <Link to="/services" onClick={toggleMobileMenu} activeClassName="font-semibold" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover-bg-gray-100 md:hover-bg-transparent md:hover-text-blue-700 md:p-0 ">Services</Link>
                                </li>

                                <li>
                                    <Link to="/faqs" onClick={toggleMobileMenu} activeClassName="font-semibold" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover-bg-gray-100 md:hover-bg-transparent md:hover-text-blue-700 md:p-0 ">FAQs</Link>
                                </li>
                            </ul>
                        </div>
                        <a href='tel:440-885-1111' className='pointer' aria-label='phone'>
                            <div className="p-4 flex">
                                <div className='p-2 mr-3 border border-[#1376F8] rounded-lg'>
                                    <CallIcon sx={{ color: '#1376F8' }} />
                                </div>
                                <div>
                                    <p className="text-black text-[14px] font-semibold">Please call for appointment</p>
                                    <p className="text-[14px]">440-885-1111</p>
                                </div>
                            </div>
                        </a>
                    </div>
                )}
            </nav >
        </>
    )
}

export default Navbar
