exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-cleanings-hygiene-js": () => import("./../../../src/pages/services/cleanings-hygiene.js" /* webpackChunkName: "component---src-pages-services-cleanings-hygiene-js" */),
  "component---src-pages-services-cosmetic-dentistry-js": () => import("./../../../src/pages/services/cosmetic-dentistry.js" /* webpackChunkName: "component---src-pages-services-cosmetic-dentistry-js" */),
  "component---src-pages-services-dentures-js": () => import("./../../../src/pages/services/dentures.js" /* webpackChunkName: "component---src-pages-services-dentures-js" */),
  "component---src-pages-services-emergency-dental-js": () => import("./../../../src/pages/services/emergency-dental.js" /* webpackChunkName: "component---src-pages-services-emergency-dental-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-preventive-dentistry-js": () => import("./../../../src/pages/services/preventive-dentistry.js" /* webpackChunkName: "component---src-pages-services-preventive-dentistry-js" */),
  "component---src-pages-services-restorative-dentistry-js": () => import("./../../../src/pages/services/restorative-dentistry.js" /* webpackChunkName: "component---src-pages-services-restorative-dentistry-js" */),
  "component---src-pages-services-root-canal-js": () => import("./../../../src/pages/services/root-canal.js" /* webpackChunkName: "component---src-pages-services-root-canal-js" */)
}

