import React from "react";
import { container } from "./style.js"
import { Link } from 'gatsby'

const Footer = () => {
    return (
        <>
            <footer className="relative pt-10 pb-5 rounded-t-lg bg-secondary-dark mt-[70px]">
                <div className={`${container}`}>
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full px-4 sm:w-2/3 lg:w-4/12">
                            <div className="w-full mb-10">
                                <p className="self-center mb-8 font-semibold whitespace-nowrap text-[19px]">Parma Family Dental Center</p>
                                <p className={`text-primary-lighter mb-7 text-[16px] w-[80%] leading-7`}>
                                    We offer personalized, gentle care in a warm environment. From cleanings to enhancements, we're here for you. Schedule your appointment today.
                                </p>
                            </div>
                        </div>

                        <LinkGroup header="Explore our services">
                            <NavLink link="/services/cosmetic-dentistry" label="Cosmetic Dentistry" />
                            <NavLink link="/services/preventive-dentistry" label="Preventive Dentistry" />
                            <NavLink link="/services/restorative-dentistry" label="Restorative Dentistry" />
                            <NavLink link="/services/cleanings-hygiene" label="Cleanings & Hygiene" />
                            <NavLink link="/services/root-canal" label="Root Canal Therapy" />
                            <NavLink link="/services/emergency-dental" label="Emergency Dental Care" />
                            <NavLink link="/services/dentures" label="Complete & Partial Dentures" />
                        </LinkGroup>

                        <LinkGroup header="Quick Links" quick={true}>
                            <NavLink link="/about-us" label="About Us" />
                            <NavLink link="/services" label="Our Services" />
                            <NavLink link="/faqs" label="FAQs" />
                        </LinkGroup>

                        <div className="w-full px-4 sm:w-1/2 lg:w-3/12">
                            <div className="w-full mb-10">
                                <h4 className="text-lg font-semibold mb-6 text-dark">
                                    Give us a call
                                </h4>
                                <p className="flex items-center text-sm font-medium text-dark mb-6">
                                    <span className="mr-3 text-primary">
                                        <svg width={19} height={21} viewBox="0 0 19 21" className="fill-current" >
                                            <path d="M17.8076 11.8129C17.741 11.0475 17.1088 10.5151 16.3434 10.5151H2.16795C1.40261 10.5151 0.803643 11.0808 0.703816 11.8129L0.00502514 18.8008C-0.0282506 19.2001 0.104853 19.6327 0.371059 19.9322C0.637265 20.2317 1.03657 20.398 1.46916 20.398H17.0755C17.4748 20.398 17.8741 20.2317 18.1736 19.9322C18.4398 19.6327 18.5729 19.2334 18.5396 18.8008L17.8076 11.8129ZM17.2751 19.1668C17.2419 19.2001 17.1753 19.2667 17.0422 19.2667H1.46916C1.36933 19.2667 1.2695 19.2001 1.23623 19.1668C1.20295 19.1336 1.1364 19.067 1.16968 18.9339L1.86847 11.9127C1.86847 11.7463 2.00157 11.6465 2.16795 11.6465H16.3767C16.5431 11.6465 16.6429 11.7463 16.6762 11.9127L17.375 18.9339C17.3417 19.0337 17.3084 19.1336 17.2751 19.1668Z" />
                                            <path d="M9.25704 13.1106C7.95928 13.1106 6.92773 14.1422 6.92773 15.4399C6.92773 16.7377 7.95928 17.7693 9.25704 17.7693C10.5548 17.7693 11.5863 16.7377 11.5863 15.4399C11.5863 14.1422 10.5548 13.1106 9.25704 13.1106ZM9.25704 16.6046C8.6248 16.6046 8.09239 16.0722 8.09239 15.4399C8.09239 14.8077 8.6248 14.2753 9.25704 14.2753C9.88928 14.2753 10.4217 14.8077 10.4217 15.4399C10.4217 16.0722 9.88928 16.6046 9.25704 16.6046Z" />
                                            <path d="M0.802807 6.05619C0.869358 7.52032 2.16711 8.11928 2.83263 8.11928H5.16193C5.19521 8.11928 5.19521 8.11928 5.19521 8.11928C6.19348 8.05273 7.19175 7.38722 7.19175 6.05619V5.25757C8.28985 5.25757 10.8188 5.25757 11.9169 5.25757V6.05619C11.9169 7.38722 12.9152 8.05273 13.9135 8.11928H13.9467H16.2428C16.9083 8.11928 18.206 7.52032 18.2726 6.05619C18.2726 5.95636 18.2726 5.59033 18.2726 5.25757C18.2726 4.99136 18.2726 4.75843 18.2726 4.72516C18.2726 4.69188 18.2726 4.6586 18.2726 4.6586C18.1727 3.72688 17.84 2.96154 17.2743 2.36258L17.241 2.3293C16.4091 1.56396 15.4109 1.13138 14.6455 0.865169C12.416 0 9.62088 0 9.48778 0C7.52451 0.0332757 6.26003 0.199654 4.36331 0.865169C3.63125 1.0981 2.63297 1.53068 1.80108 2.29603L1.7678 2.3293C1.20212 2.92827 0.869359 3.69361 0.769531 4.62533C0.769531 4.6586 0.769531 4.69188 0.769531 4.69188C0.769531 4.75843 0.769531 4.95809 0.769531 5.22429C0.802807 5.52377 0.802807 5.92308 0.802807 6.05619ZM2.5997 3.12792C3.26521 2.52896 4.09711 2.16292 4.7959 1.89672C6.52624 1.26448 7.65761 1.13138 9.55433 1.0981C9.68743 1.0981 12.2829 1.13138 14.2795 1.89672C14.9783 2.16292 15.8102 2.49568 16.4757 3.12792C16.8417 3.52723 17.0746 4.05964 17.1412 4.69188C17.1412 4.79171 17.1412 4.95809 17.1412 5.22429C17.1412 5.55705 17.1412 5.92308 17.1412 6.02291C17.1079 6.78825 16.3759 6.95463 16.276 6.95463H13.98C13.6472 6.92135 13.1148 6.78825 13.1148 6.05619V4.69188C13.1148 4.42567 12.9485 4.22602 12.7155 4.12619C12.5159 4.05964 6.69262 4.05964 6.49296 4.12619C6.26003 4.19274 6.09365 4.42567 6.09365 4.69188V6.05619C6.09365 6.78825 5.56124 6.92135 5.22848 6.95463H2.93246C2.83263 6.95463 2.10056 6.78825 2.06729 6.02291C2.06729 5.92308 2.06729 5.55705 2.06729 5.22429C2.06729 4.95809 2.06729 4.82498 2.06729 4.72516C2.00073 4.05964 2.23366 3.52723 2.5997 3.12792Z" />
                                        </svg>
                                    </span>
                                    <a href='tel:440-885-1111' className='pointer text-primary-lighter'>
                                        440-885-1111
                                    </a>
                                </p>
                                <h4 className="text-lg font-semibold mb-6 text-dark mt-10">
                                    Visit Us
                                </h4>
                                <div className="flex items-center text-sm font-medium text-dark">
                                    <span className="mr-3 text-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="21" width="19" viewBox="0 0 384 512">
                                            <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                        </svg>
                                    </span>
                                    <a href="https://www.google.com/maps/place/5500+Ridge+Rd+%23135,+Cleveland,+OH+44129/@41.411661,-81.734667,18z/data=!4m6!3m5!1s0x8830ef1d7e05075d:0x7beda41ad8413518!8m2!3d41.4116614!4d-81.7346671!16s%2Fg%2F11qzd9q28y?hl=en&entry=ttu" target="_blank" rel="noreferrer">
                                        <p className="text-primary-lighter">
                                            5500 Ridge Road, Suite 135 Parma Ohio 44129
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <span className='line bg-gray'></span>
                    <p className='text-center mt-6 font-normal text-[13px] sm:text-[16px]'>Parma Family Dental Center 2023 © All Rights Reserved.</p>
                    <div className="text-center mt-6 font-normal text-[12px] sm:text-[14px] text-indigo-700">Designed by Technfo, LLC</div>
                </div>
            </footer>
        </>
    );
};

export default Footer;

const LinkGroup = ({ children, header, quick }) => {
    return (
        <>
            <div className={`w-full px-4 sm:w-1/2 ${quick ? "lg:w-2/12" : "lg:w-3/12"}`}>
                <div className="w-full mb-10">
                    <h4 className="text-lg font-semibold mb-6">{header}</h4>
                    <ul>{children}</ul>
                </div>
            </div>
        </>
    );
};

const NavLink = ({ link, label }) => {
    return (
        <div>
            <li>
                <Link to={link} className={`inline-block mb-2 leading-loose text-[13px] lg:text-[15px]`} >
                    {label}
                </Link>
            </li>
        </div>
    );
};
